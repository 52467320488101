export const environment = {
  name: 'oc2021',
  production: true,
  apiUrl: 'https://oclabqaapi.azurewebsites.net/',
  videoUrl: 'https://ocsessions.blob.core.windows.net/videos/',
  whiteLabel: 'lab',
  hasVideoUploadFeature: true,
  joinNowButton: true,
  homepageUrl: '/events',
  hasLogrocket: true,
  version: '2.50',
  build: '1057',
  frontendEnabled: false,
  customHomepageURL: '/rsvp',
  logoLight: 'https://oneclick.chat/assets/images/logo.png',
  logoDark: '',
  companyName: 'OneClick',
  companyLongName: 'OneClick.Chat',
  feedbackRedirectURL: '/left',
  headerBackgroundColor: '#095dfb',
  cmsURL: 'https://cloud.squidex.io/api/content/caregiverengage',
  cmsURLAssets: 'https://cloud.squidex.io/api/assets/caregiverengage',
  firebase: {
    apiKey: 'AIzaSyDeSpVb0g05cF4Msx34i6_ll5QEGwq8ieM',
    authDomain: 'oneclickchatillinois.firebaseapp.com',
    projectId: 'oneclickchatillinois',
    storageBucket: 'oneclickchatillinois.appspot.com',
    messagingSenderId: '368099839906',
    appId: '1:368099839906:web:34f45a89d9e0614fbaecfb',
    databaseURL: 'https://oneclickchatillinois-default-rtdb.firebaseio.com',
  },
};
