import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { Room, User } from '@models/index';

export interface TokenRequest {
  room: string;
  role: string;
}
export interface TokenResponse {
  apiKey: string;
  sessionId: string;
  token: string;
}

@Injectable({ providedIn: 'root' })
export class ManagementApiService {
  constructor(private http: HttpClient, private firestore: AngularFirestore) { }

  public getRooms(organizationId): Observable<Room> {
    const fromData = new FormData();
    fromData.append('organizationId', organizationId);
    return this.http
      .post(environment.apiUrl + 'management/get-rooms', fromData)
      .pipe(map((response) => response as Room));
  }

  public getFeedback(): Observable<Room> {
    const fromData = new FormData();
    return this.http
      .post(environment.apiUrl + 'management/get-feedbacks', fromData)
      .pipe(map((response) => response as Room));
  }

  public getFeedbackDetails(roomId): Observable<Room> {
    const fromData = new FormData();
    fromData.append('roomId', roomId);
    return this.http
      .post(environment.apiUrl + 'management/get-feedback-details', fromData)
      .pipe(map((response) => response as Room));
  }


  public getQuestions(roomId: string): Observable<any[]> {
    const fromData = new FormData();
    fromData.append('roomId', roomId);

    return this.http
      .post(environment.apiUrl + 'qa/get-questions', fromData)
      .pipe(map((response) => response as []));
  }

  public getAnswers(roomId: string): Observable<any[]> {
    const fromData = new FormData();
    fromData.append('roomId', roomId);

    return this.http
      .post(environment.apiUrl + 'qa/get-answers', fromData)
      .pipe(map((response) => response as []));
  }
  
  public getEvents(organizationId): Observable<Room> {
    const fromData = new FormData();
    fromData.append('organizationId', organizationId);
    return this.http
      .post(environment.apiUrl + 'management/get-events', fromData)
      .pipe(map((response) => response as Room));
  }

  public getOrganization(): Observable<Room> {
    const fromData = new FormData();
    return this.http
      .post(environment.apiUrl + 'management/get-organization', fromData)
      .pipe(map((response) => response as Room));
  }


  public checkAdmin(): Observable<Room> {
    const fromData = new FormData();
    return this.http
      .post(environment.apiUrl + 'user/is-admin', fromData)
      .pipe(map((response) => response as Room));
  }


  public addRoom(organizationId, name, email, roomName, roomUrl): Observable<Room> {
    const fromData = new FormData();
    fromData.append('organizationId', organizationId);
    fromData.append('name', name);
    fromData.append('email', email);
    fromData.append('roomName', roomName);
    fromData.append('roomUrl', roomUrl);

    return this.http
      .post(environment.apiUrl + 'management/add-room', fromData)
      .pipe(map((response) => response as Room));
  }

  public deleteRoom(organizationId, roomId): Observable<Room> {
    const fromData = new FormData();
    fromData.append('organizationId', organizationId);
    fromData.append('roomId', roomId);

    return this.http
      .post(environment.apiUrl + 'management/remove-room', fromData)
      .pipe(map((response) => response as Room));
  }

  public addUserToOrganization(label, name): Observable<Room> {
    const fromData = new FormData();
    fromData.append('label', label);
    fromData.append('name', name);

    return this.http
      .post(environment.apiUrl + 'management/add-user-to-organization', fromData)
      .pipe(map((response) => response as Room));
  }


}
