<div id="wrapper">
    <app-admin-header [organization]="organization"></app-admin-header>

    <div *ngIf="isAdmin === 0" class="admin-mode-container">
        <div class="row mg-b-10 mg-t-10">
            <div class="col-9">
                Restricted access
            </div>
        </div>
    </div>

    <div *ngIf="isAdmin === 1" class="admin-mode-container">
        <div class="row mg-b-10">
            <div class="col-9">
                <h4 id="section1" class="float-left">Events</h4>
                <button *ngIf="allOrganization && allOrganization.length < 1" routerLink="/admin/manage-account"
                    class="btn btn-sm btn-primary float-left ml-4"> <i class="fa fa-plus"></i> Add organization</button>
            </div>
            <div class="col-3">
                <div class="float-right btn-group" role="group">
                    <button [disabled]="loading"
                        [ngClass]="{ 'btn-primary': eventsFilter == 'upcoming' , 'btn-white': eventsFilter == 'past' }"
                        (click)="setFilter('upcoming')" type="button" class="btn btn-primary">Upcoming Events</button>
                    <button [disabled]="loading"
                        [ngClass]="{ 'btn-primary': eventsFilter == 'past' , 'btn-white': eventsFilter == 'upcoming' }"
                        (click)="setFilter('past')" type="button" class="btn">Past Events</button>
                </div>
            </div>
        </div>

        <div class="table-responsive" *ngIf="showEvents && showEvents.length > 0">
            <table class="table table-striped mg-b-0">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Start Date </th>
                        <!-- <th scope="col">End Date</th> -->
                        <th scope="col">Room Link</th>
                        <th scope="col">RSVPs</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let r of showEvents">
                        <th scope="row">{{r.name}}</th>
                        <td>{{r.startedOn | date: 'MMM d, y, h:mm:ss a'}}</td>
                        <td><a href="https://{{r.uRL}}" target="_blank">{{r.uRL}}</a></td>

                        <!-- <td>{{r.endedOn  | date}}</td> -->
                        <td>
                            {{r.participantEmails.length}}
                            <button class="btn btn-link btn-sm"
                                (click)="selectedParticipant = r; dialog.toggle('participantsDetails');">Details</button>
                        </td>
                    </tr>

                </tbody>
            </table>

        </div>

        <div class="table-responsive mt-5" *ngIf="showEvents && showEvents.length < 1">
            <p>No Events found.</p>
        </div>
        <div class="table-responsive mt-5" *ngIf="loading">
            <p>Loading...</p>
        </div>
      
       
    </div>

   
    
</div>

<app-dialog name="participantsDetails" header="Participants">
    <div class="participants">
        <div class="participants__group">
            <div class="form col-md-12 align-self-end">
                <div class="row">
                    <div class="col-12 mt-2 pd-0" *ngIf="selectedParticipant">
                        <table class="table table-striped mg-b-0">
                            <thead>
                                <tr>
                                    <th scope="col">Email</th>
                                </tr>
                            </thead>
                            <tr *ngFor="let x of selectedParticipant?.participantEmails">
                                <td>
                                    {{x}}
                                </td>
                            </tr>

                            <tr *ngIf="selectedParticipant?.participantEmails.length < 1">
                                <td>
                                   No Participants
                                </td>
                            </tr>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </div>
</app-dialog>