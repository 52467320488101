import { Component, OnInit } from '@angular/core';
import { DialogService } from '../../dialogs/dialog.service';
import { ApiService } from '@services/index';
import { NotificationService } from '@services/index';

@Component({
  selector: 'app-manage-feedback',
  templateUrl: './manage-feedback.component.html',
  styleUrls: ['./manage-feedback.component.css']
})
export class ManageFeedbackComponent implements OnInit {

  allFeedback;
  organization;
  loading = false;
  allOrganization;
  isAdmin = -1;
  showDetails = false;
  feedbackDetails;
  selectedRoom;
  allQuestions;
  allAnswers;
  constructor(
    public dialog: DialogService,
    public api: ApiService,
    public notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.api.management.checkAdmin().subscribe((r: any) => {
      this.loading = false;
      if (r === true) {
        this.isAdmin = 1;
      } else {
        this.isAdmin = 0;
      }
    }, (error) => {
      this.isAdmin = 0;
      this.notificationService.error("Error calling API");
    });

    this.getFeedback();

  }

  getFeedback() {
    this.loading = true;

    this.api.management.getFeedback().subscribe((r) => {
      this.allFeedback = r;
      this.loading = false;
    });
  }

  showFeedbackDetails(ev:any) {
    this.loading = true;
    this.showDetails = true;
    this.selectedRoom = ev;
    this.api.management.getFeedbackDetails(ev.roomId).subscribe((r) => {
      this.feedbackDetails = r;
      this.loading = false;
    });

    this.api.management.getQuestions(ev.roomId).subscribe((r) => {
      this.allQuestions = r;
    });

    this.api.management.getAnswers(ev.roomId).subscribe((r) => {
      this.allAnswers = r.reverse();
    });
  }

}
