import { Component, OnInit } from '@angular/core';
import { DialogService } from '../../dialogs/dialog.service';
import { ApiService } from '@services/index';
import { NotificationService } from '@services/index';

@Component({
  selector: 'app-manage-events',
  templateUrl: './manage-events.component.html',
  styleUrls: ['./manage-events.component.css']
})
export class ManageEventsComponent implements OnInit {

  allEvents = [];
  showEvents;
  organization;
  loading = false;
  allOrganization;
  eventsFilter = 'upcoming';
  selectedParticipant;
  isAdmin = -1;

  constructor(
    public dialog: DialogService,
    public api: ApiService,
    public notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.api.management.checkAdmin().subscribe((r: any) => {
      if (r === true) {
        this.isAdmin = 1;
      } else {
        this.isAdmin = 0;
      }
    }, (error) => {
      this.isAdmin = 0;
      this.notificationService.error("Error calling API");
    });
    this.getEvents();
  }

  getEvents() {
    this.loading = true;
    this.api.management.getEvents("").subscribe((r: any) => {
      this.loading = false;

      this.allEvents = r;
      if (this.eventsFilter === 'upcoming') {
        this.showEvents = this.allEvents.filter((r) => { return new Date(r.startedOn) > new Date() });
      } else {
        this.showEvents = this.allEvents.filter((r) => { return new Date(r.startedOn) < new Date() });
      }
      this.allEvents.forEach((r) => {
        if(r.participantEmails.length > 0) {
          console.log(r);
        }
      })
    });
  }

  setFilter(type) {
    this.eventsFilter = type;
    if (this.eventsFilter === 'upcoming') {
      this.showEvents = this.allEvents.filter((r) => { return new Date(r.startedOn) > new Date() });
    } else {
      this.showEvents = this.allEvents.filter((r) => { return new Date(r.startedOn) < new Date() });
    }
  }

}
